import { EditOutlined } from "@ant-design/icons";
import { Divider, List, Space, Typography } from "antd";
import React from "react";
import DividerTitle from "./DividerTitle";

interface Item {
  label?: string;
  value: React.ReactNode;
  onClick?: () => void;
  onEdit?: () => void;
}

interface HorizontalItemDisplayProps {
  items?: Item[];
  title?: string;
  columns?: number;
}

const HorizontalItemDisplay: React.FC<HorizontalItemDisplayProps> = ({
  items,
  title,
  columns = 1,
}) => {
  return (
    <>
      {title && (
        <DividerTitle title={title} />
      )}
      <List
        grid={{ gutter: [20, 20], column: columns }}
        style={{ marginBottom: 32}}
        dataSource={items}
        renderItem={(item) => (
          <List.Item>
            <Space direction="vertical">
              {item.label && (
                <Typography.Text
                  style={{
                    color: "#8c8c8c",
                    fontSize: "0.875rem",
                    transition: "color 0.3s ease",
                  }}
                >
                  {item.label}{" "}
                  {item.onEdit && (
                    <EditOutlined
                      onClick={item.onEdit}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Typography.Text>
              )}
              <Space
                style={{
                  textAlign: item.label ? "left" : "right",
                  cursor: item.onClick ? "pointer" : "default",
                }}
                direction={"vertical"}
                onClick={item.onClick}
                onMouseEnter={(e) => {
                  if (item.onClick && item.label) {
                    (
                      e.currentTarget.querySelector(
                        ".ant-typography"
                      ) as HTMLElement
                    ).style.color = "#595959";
                  }
                }}
                onMouseLeave={(e) => {
                  if (item.onClick && item.label) {
                    (
                      e.currentTarget.querySelector(
                        ".ant-typography"
                      ) as HTMLElement
                    ).style.color = "#8c8c8c";
                  }
                }}
              >
                <>{item.value}</>
              </Space>
            </Space>
          </List.Item>
        )}
      />
    </>
  );
};

export default HorizontalItemDisplay;
