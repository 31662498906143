import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Button, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";

const PhoneSection = ({
  onPhoneSubmit,
}: {
  onPhoneSubmit: (data: any) => void;
}) => {
  const translate = useTranslate();
  const [form] = Form.useForm();
  const handleFormSubmit = () => {
    const values = form.getFieldsValue();
    onPhoneSubmit(values.phone_numbers);
  };
  useEffect(() => {
    form.setFieldsValue({
      phone_numbers: [{ phone_number: "", label: "" }],
    });
  }, [form]);
  return (
    <Form
      form={form}
      layout="vertical"
      onValuesChange={(changedValues) => {
        if (Object.keys(changedValues).length > 0) {
          handleFormSubmit();
        }
      }}
      onFinish={handleFormSubmit}
    >
      <Form.List name="phone_numbers">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div
                key={key}
                style={{
                  position: "relative",
                  border: "1px solid #d9d9d9",
                  padding: "16px",
                  borderRadius: "4px",
                  marginBottom: "16px",
                }}
              >
                <CloseOutlined
                  onClick={() => remove(key)}
                  style={{
                    position: "absolute",
                    top: "8px",
                    right: "8px",
                    cursor: "pointer",
                    color: "black",
                  }}
                />
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={[name, "phone_number"]}
                      label={translate("contacts.phone_number.label")}
                    >
                      <Input
                        placeholder={translate(
                          "contacts.phone_number.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name={[name, "phone_label"]}
                      label={translate("contacts.phone_label.label")}
                    >
                      <Input
                        placeholder={translate(
                          "contacts.phone_label.placeholder"
                        )}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
            <Button
              type="dashed"
              icon={<PlusOutlined />}
              onClick={() => add()}
              style={{ width: "100%" }}
            >
              {translate("buttons.add_phone_number")}
            </Button>
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default PhoneSection;
