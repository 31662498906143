import {
  CanAccess,
  useNavigation,
  useShow,
  useTranslate
} from "@refinedev/core";
import React, { PropsWithChildren} from "react";
import {Drawer, Skeleton } from "antd";
import { useParams } from "react-router-dom";
import OrderMainView from "../../../components/shop/orders/OrderMainView";

const OrdersShow: React.FC<PropsWithChildren> = () => {
  const translate = useTranslate();
  const params = useParams();
  const { list } = useNavigation();
  const { query } = useShow({
    resource: "orders",
    id: params.subscriptionId,
    meta: {
      select:
        "*, invoice_contact(*, phone_numbers(*)), invoice_contact_address(*),  contact_person_id(*), delivery_contact(*), delivery_contact_address(*), product(type), invoices(*, parent_invoice:parent(*)), product_items(*)",
    },
  });

  const closeModal = () => {
    list("orders", "replace");
  };

  const { data, isLoading } = query;

  const order = data?.data ?? {};

  return (
    <CanAccess fallback={translate("module.noAccessTitle")}>
      <Drawer
        open
        styles={{ header: { display: 'none' }}}
        onClose={() => closeModal()}
        width={"100%"}
      >
        <Skeleton loading={isLoading} active>
          <OrderMainView
            orderId={order.id as number}
            onClose={() => closeModal()}
          />
        </Skeleton>
      </Drawer>
    </CanAccess>
  );
};

export default OrdersShow;
