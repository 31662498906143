import {
  useList,
} from "@refinedev/core";
import React, {
  PropsWithChildren,
  createContext,
} from "react";
import {useUser} from "../ContextProvider";

type SearchContextType = {
  searchKey: string;
  searchKeyValidUntil: any;
  isLoading: boolean;
  isFetching: boolean;
  isError: boolean;
};
export const SearchContext = createContext<SearchContextType>({
  searchKey: "",
  searchKeyValidUntil: "",
  isLoading: false,
  isFetching: false,
  isError: false,
});

export const SearchProvider: React.FC<
  PropsWithChildren<{ }>
> = ({ children }) => {

  const { currentTeam } = useUser();
  const { data, isLoading, isFetching, isError } = useList({
    resource: "search",
    queryOptions: {
      enabled: !!currentTeam?.account_id, // Verhindert die Abfrage, wenn account_id nicht vorhanden ist
    },
    meta: {
      schema: "basejump", // Schema explizit setzen
      filters: [{ field: "account", operator: "eq", value: currentTeam?.account_id }],
    },
  });

  const entry = data?.data?.[0]; // Erstes Element aus der Liste holen
  const searchKey = entry?.search_key ?? "";
  const searchKeyValidUntil = entry?.search_key_valid_until;

  return (
    <SearchContext.Provider
      value={{
        searchKey: searchKey ?? "",
        searchKeyValidUntil,
        isLoading,
        isFetching,
        isError,
      }}
    >
      {data && children}
    </SearchContext.Provider>
  );
};
