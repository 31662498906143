import React from "react";
import { Modal, Space, Typography, Divider } from "antd";
import { useTranslate } from "@refinedev/core";
import dayjs from "dayjs";

import FileDisplay from "../../invoices/FileDisplay";
import { numberToCurrency } from "../../../utility";
import ContactDetails from "../../contacts/ContactDetails";
import HorizontalItemDisplay from "../../common/HorizontalItemDisplay";

/**
 * Props for the invoice modal component
 */
interface OrdersShowInvoiceProps {
  // The invoice object to display; can be "undefined" or null if modal is closed
  showInvoice?: any;
  // Callback to close/hide the modal
  onClose: () => void;
  // Parent order object (used to display invoice contact details)
  order: any;
}

export const OrdersShowInvoice: React.FC<OrdersShowInvoiceProps> = ({
                                                                      showInvoice,
                                                                      onClose,
                                                                      order,
                                                                    }) => {
  const translate = useTranslate();

  // Compute the final invoice_number if parent_invoice exists
  const invoice_number =
    showInvoice?.parent_invoice?.invoice_number || showInvoice?.invoice_number;

  // For safety, check if no invoice data is passed
  const isModalOpen = showInvoice !== undefined && showInvoice !== null;

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={onClose}
      title={translate("billing_details.title")}
    >
      {/* Use Space to arrange basic invoice info */}
      <Space direction="vertical" style={{ width: "100%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text strong>Erstellt:</Typography.Text>
          <Typography.Text>
            {dayjs(showInvoice?.created_at).format(
              translate("global.date_time_format")
            )}
          </Typography.Text>
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text strong>Status:</Typography.Text>
          <Typography.Text>
            {translate(`billing_details.status.${showInvoice?.status}`)}
          </Typography.Text>
        </div>

        {/* Payment info */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text strong>
            {/* If amount is unpaid, partially paid, etc. */}
            {showInvoice?.amount_paid === 0 && (
              <>{translate(`billing_details.status.open`)}:</>
            )}
            {showInvoice?.amount_paid > 0 &&
              showInvoice?.amount_paid !== showInvoice?.amount && (
                <>{translate(`billing_details.status.partially_paid`)}:</>
              )}
          </Typography.Text>
          <Typography.Text>
            {showInvoice?.amount_paid === 0 && (
              <>{numberToCurrency(showInvoice?.amount || 0)}</>
            )}
            {showInvoice?.amount_paid > 0 &&
              showInvoice?.amount_paid !== showInvoice?.amount && (
                <>
                  {`${numberToCurrency(showInvoice?.amount_paid || 0)} 
                                    ${translate("global.of")} 
                                    ${numberToCurrency(showInvoice?.amount || 0)}`}
                </>
              )}
          </Typography.Text>
        </div>

        {/* Shipping info */}
        {showInvoice?.shipping_conditions?.shippingType && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text strong>
              {translate(
                `subscriptions.fields.execution_date_${showInvoice?.shipping_conditions?.shippingType}`
              )}
              :
            </Typography.Text>
            <Typography.Text>
              {dayjs(
                showInvoice?.shipping_conditions?.shippingDate
              ).format(translate("global.date_format"))}
              {showInvoice?.shipping_conditions?.shippingType ===
                "serviceperiod" &&
                showInvoice?.shipping_conditions?.shippingEndDate && (
                  <>
                    {" "}
                    -{" "}
                    {dayjs(
                      showInvoice?.shipping_conditions?.shippingEndDate
                    ).format(translate("global.date_format"))}
                  </>
                )}
            </Typography.Text>
          </div>
        )}

        {/* Optional invoice_number */}
        {invoice_number && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography.Text strong>
              {translate("settings.invoices.fields.invoice_number")}:
            </Typography.Text>
            <Typography.Text>{invoice_number}</Typography.Text>
          </div>
        )}
      </Space>

      <Divider />

      {/* Address/Recipient */}
      <HorizontalItemDisplay
        items={[
          {
            label: translate("billing_details.recipient"),
            value: (
              <>
                <ContactDetails
                  contact={order?.invoice_contact}
                  address={order?.invoice_contact_address}
                  contact_person={order?.contact_person_id}
                />
                <Typography>{order?.invoice_contact?.email}</Typography>
              </>
            ),
          },
        ]}
      />

      <Divider />

      {/* File attachments (PDF, etc.) */}
      <FileDisplay
        label={translate("files.files")}
        invoice={showInvoice}
      />
    </Modal>
  );
};
