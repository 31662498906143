import React from "react";
import { Flex, Typography } from "antd";
import dayjs from "dayjs";
import { useTranslate } from "@refinedev/core";
import {numberToCurrency} from "../../../utility";

interface Invoice {
  id: string;
  name: string;
  created_at: string;
  amount: number;
  invoice_number?: string;
}

interface ListInvoicesProps {
  invoices: Invoice[];
  onClick: (invoice: Invoice) => void;
}

const ListInvoices: React.FC<ListInvoicesProps> = ({
                                                     invoices,
                                                     onClick,
                                                   }) => {
  const translate = useTranslate();

  return invoices?.length > 0 ? (
    <>
      {invoices.map((invoice) => (
        <Flex
          onClick={() => onClick(invoice)}
          justify={"space-between"}
          key={invoice.id}
          title={invoice.name}
          style={{
            marginBottom: 16,
            padding: "6px 16px",
            borderRadius: "8px",
            cursor: "pointer",
            backgroundColor: "#f0f0f0",
            transition: "background-color 0.3s, transform 0.2s",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = "#e4e5e6")
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = "#f0f0f0")
          }
        >
          <Typography.Text>
            {dayjs(invoice.created_at).format(
              translate("global.date_format")
            )}
          </Typography.Text>
          <Typography.Text style={{ marginLeft: 8 }}>
            {invoice.invoice_number}
          </Typography.Text>
          <Typography.Text style={{ marginLeft: 8 }}>
            {numberToCurrency(invoice.amount)}
          </Typography.Text>
        </Flex>
      ))}
    </>
  ) : (
    <Typography.Text type="secondary">
      {translate("commerce.no_invoices")}
    </Typography.Text>
  );
};

export default ListInvoices;
