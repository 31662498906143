import { useCreate, useTranslate } from "@refinedev/core";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import React, { useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";

const { Option } = Select;

interface AddressModalProps {
  visible: boolean;
  onClose: () => void;
  onSuccess: (any) => void;
  contactId: any;
}

const AddressModal: React.FC<AddressModalProps> = ({
  visible,
  onClose,
  onSuccess,
  contactId,
}) => {
  const { currentTeam } = useUser();
  const { mutateAsync: createOne } = useCreate();
  const translate = useTranslate();
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState("Home_Address");

  const handleTypeChange = (changedValues: any) => {
    if (changedValues.type) {
      setSelectedType(changedValues.type);
    }
  };

  const renderCommonFields = () => (
    <>
      <Form.Item
        name="zip"
        label={translate("contacts.zip")}
        rules={[{ required: true }]}
      >
        <Input placeholder={translate("contacts.zip")} />
      </Form.Item>
      <Form.Item
        name="city"
        label={translate("contacts.city")}
        rules={[{ required: true }]}
      >
        <Input placeholder={translate("contacts.city")} />
      </Form.Item>
      <Form.Item
        name="country"
        label={translate("contacts.country")}
        rules={[{ required: true }]}
      >
        <Select
          placeholder={translate("contacts.country")}
          options={[
            { value: "de", label: translate("countries.DE") },
            { value: "at", label: translate("countries.AT") },
            { value: "ch", label: translate("countries.CH") },
            { value: "us", label: translate("countries.US") },
            { value: "fr", label: translate("countries.FR") },
            { value: "uk", label: translate("countries.UK") },
            { value: "es", label: translate("countries.ES") },
            { value: "it", label: translate("countries.IT") },
          ]}
          allowClear
        />
      </Form.Item>
    </>
  );

  const renderAddressFields = (type: string) => {
    let typeSpecificFields;
    switch (type) {
      case "Home_Address":
        typeSpecificFields = (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"address_supplement_1"}
                label={translate("contacts.address_optional")}
              >
                <Input placeholder={translate("contacts.address_optional")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="street"
                label={translate("contacts.street")}
                rules={[{ required: true }]}
              >
                <Input placeholder={translate("contacts.street")} />
              </Form.Item>
            </Col>
          </Row>
        );
        break;
      case "PO_Box_Address":
        typeSpecificFields = (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={"street"}
                label={translate("contacts.po_box_address")}
                rules={[{ required: true }]}
              >
                <Input placeholder={translate("contacts.po_box_address")} />
              </Form.Item>
            </Col>
          </Row>
        );
        break;
      case "Packing_Station":
        typeSpecificFields = (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="address_supplement_1"
                label={translate("contacts.post_number")}
              >
                <Input placeholder={translate("contacts.post_number")} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="street"
                label={translate("contacts.packstation")}
                rules={[{ required: true }]}
              >
                <Input placeholder={translate("contacts.packstation")} />
              </Form.Item>
            </Col>
          </Row>
        );
        break;
      default:
        break;
    }

    return (
      <>
        {typeSpecificFields}
        {renderCommonFields()}
      </>
    );
  };

  const handleAddAddress = async (values: any) => {
    const data = await createOne({
      resource: "addresses",
      meta: { select: "*" },
      values: {
        ...values,
        account: currentTeam?.account_id,
      },
    });
    await createOne({
      resource: "contact_has_address",
      values: {
        contact: contactId,
        address: data.data?.id,
        account: currentTeam?.account_id,
      },
    });
    form.resetFields();
    onSuccess(data.data);
    onClose();
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      centered
      footer={null}
      destroyOnClose={true}
      title={translate("contacts.add_address")}
    >
      <Form
        form={form}
        onFinish={handleAddAddress}
        layout="vertical"
        onValuesChange={handleTypeChange}
        initialValues={{ type: selectedType }}
      >
        <Form.Item name="label" label={translate("contacts.label")}>
          <Input placeholder={translate("contacts.label")} />
        </Form.Item>
        <Form.Item
          name="type"
          label={translate("contacts.address_modal.type")}
          rules={[{ required: true }]}
        >
          <Select placeholder={translate("contacts.address_modal.type")}>
            <Option value="Home_Address">
              {translate("contacts.address_modal.home_address")}
            </Option>
            <Option value="PO_Box_Address">
              {translate("contacts.address_modal.po_box_address")}
            </Option>
            <Option value="Packing_Station">
              {translate("contacts.address_modal.packing_station")}
            </Option>
          </Select>
        </Form.Item>

        {renderAddressFields(selectedType)}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {translate("buttons.save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddressModal;
