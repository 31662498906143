import { PlusOutlined } from "@ant-design/icons";
import { useModalForm, useTable } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Select,
  Space,
  Table,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { useUser } from "../../../contexts/ContextProvider";

export default function IssuesTable({
  orderId,
  totalIssuesLeft,
  contact_id,
  components,
}) {
  const [actionSelected, setActionSelected] = useState();
  const [amountSelected, setAmountSelected] = useState();
  const translate = useTranslate();
  const { teamMembers, currentTeam, personalAccount } = useUser();
  const { tableProps } = useTable({
    resource: "subscription_issues",
    filters: {
      permanent: [
        {
          operator: "eq",
          field: "order_id",
          value: orderId,
        },
      ],
    },
    sorters: {
      permanent: [
        {
          field: "transaction_date",
          order: "desc",
        },
      ],
    },
    syncWithLocation: false,
    pagination: { mode: "off", pageSize: 2000 },
  });
  const { modalProps, formProps, show } = useModalForm({
    resource: "subscription_issues",
    action: "create",
    queryOptions: {
      enabled: false,
    },
  });

  const handleOnFinish = (values: any) => {
    const { action, amount, ...restValues } = values;
    const additionalField =
      action === "credit"
        ? { credit: amount }
        : action === "debit"
        ? { debit: amount }
        : {};

    formProps.onFinish &&
      formProps.onFinish({
        ...restValues,
        ...additionalField,
        account: currentTeam?.account_id,
        created_by: personalAccount.account_id,
        order_id: orderId,
        contact: contact_id,
        transaction_date: new Date(),
      });
  };

  const columns = [
    {
      title: translate("subscriptions.issues_left.transaction_date"),
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text: any) =>
        dayjs(text).format(translate("global.date_time_format")),
    },
    {
      title: translate("subscriptions.issues_left.debit"),
      dataIndex: "debit",
      key: "debit",
      render: (text: any) => (text == null ? "-" : text),
    },
    {
      title: translate("subscriptions.issues_left.credit"),
      dataIndex: "credit",
      key: "credit",
      render: (text: any) => (text == null ? "-" : text),
    },
    {
      title: translate("subscriptions.issues_left.balance"),
      dataIndex: "balance",
      key: "balance",
    },
    {
      title: translate("subscriptions.issues_left.description"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: translate("subscriptions.issues_left.period"),
      dataIndex: "period",
      key: "period",
      render: (text: any) => {
        const period = components.find((period) => period.id == text);
        return `${dayjs(period?.start_date).format(
          translate("global.date_format")
        )} - ${dayjs(period?.end_date).format(
          translate("global.date_format")
        )}`;
      },
    },
    {
      title: translate("subscriptions.issues_left.created_by"),
      dataIndex: "created_by",
      key: "created_by",
      render: (text: any) =>
        teamMembers?.find((user) => user.user_id == text)?.name,
    },
  ];
  return (
    <>
      <Table
        {...tableProps}
        columns={columns}
        rowKey="id"
        pagination={false}
        style={{ marginBottom: 10 }}
      />
      <Button
        onClick={(e) => show()}
        style={{ fontSize: "0.80rem", fontWeight: 500, padding: 7 }}
        type="link"
      >
        <Space>
          <PlusOutlined />
          {translate("subscriptions.issues_left.add_transaction")}
        </Space>
      </Button>
      <Modal {...modalProps} width={500} title="Add transaction issue">
        <Form layout="vertical" {...formProps} onFinish={handleOnFinish}>
          <Form.Item
            label="Action"
            name={"action"}
            rules={[{ required: true }]}
          >
            <Radio.Group
              onChange={(val: any) => setActionSelected(val.target.value)}
            >
              <Radio value={"debit"}>Debit</Radio>
              <Radio value={"credit"}>Credit</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Ammount"
            name={"amount"}
            rules={[{ required: true }]}
          >
            <InputNumber
              min={1}
              onChange={(val: any) => setAmountSelected(val)}
            />
          </Form.Item>
          <Form.Item
            label="Period"
            name={"period"}
            rules={[{ required: true }]}
          >
            <Select
              options={components?.map((period) => ({
                label: `${dayjs(period?.start_date).format(
                  translate("global.date_format")
                )} - ${dayjs(period?.end_date).format(
                  translate("global.date_format")
                )}`,
                value: period.id,
              }))}
            />
          </Form.Item>
          <Form.Item label="Description" name={"description"}>
            <Input.TextArea />
          </Form.Item>
          <Divider />
          <Form.Item label="Balance after the action">
            {actionSelected === "credit"
              ? totalIssuesLeft + (amountSelected ?? 0)
              : totalIssuesLeft - (amountSelected ?? 0)}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
