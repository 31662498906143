import React from "react";
import { Flex, Space, Popconfirm, Tag } from "antd";
import { useTranslate } from "@refinedev/core";
import {DoubleRightOutlined} from "@ant-design/icons";

interface OrderTitleProps {
  type: string;
  product_items: { title: string };
  name: string;
  suspended: boolean;
  handleUnsuspend: () => void;
  order_number: string;
  source_id?: string;
}

const OrderTitle: React.FC<OrderTitleProps> = ({
                                                 type,
                                                 product_items,
                                                 name,
                                                 suspended,
                                                 handleUnsuspend,
                                                 order_number,
                                                 source_id,
                                               }) => {
  const translate = useTranslate();

  return (
    <Flex align={"center"} gap={16} justify={"space-between"}>

      <span style={{ color: "#787878" }}>
        {order_number}
        {source_id && <> ({source_id})</>}
      </span>

      <DoubleRightOutlined
        style={{ fontSize: '0.7em', color: "#787878" }}
      />

      {type === "simple" && <>{translate(`orders.type.${type}`)}</>}

      {type === "subscription" && (
        <Space size={"middle"}>
          <Flex gap={8} align={"center"}>
            {product_items.title} - {name}
          </Flex>
        </Space>
      )}

      {suspended && (
        <Popconfirm
          overlayStyle={{ maxWidth: 400 }}
          title={translate("orders.suspended.title")}
          description={translate("orders.suspended.description")}
          onConfirm={handleUnsuspend}
          okText={translate("orders.suspended.resume")}
          okButtonProps={{
            type: "primary",
            style: { backgroundColor: "green" },
          }}
          cancelText={translate("orders.suspended.cancel")}
        >
          <Tag style={{ cursor: "pointer" }} color="#FACA15">
            {translate("orders.suspended.status")}
          </Tag>
        </Popconfirm>
      )}
    </Flex>
  );
};

export default OrderTitle;
