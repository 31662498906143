import React from "react";
import { Popover } from "antd";
import { ShopOutlined, UserOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core"; // oder einem globalen Übersetzungsmodul

const ContactTypeIcon = ({ type }) => {
  const translate = useTranslate();
  return (
    <>
      {type === "company" && (
        <Popover content={translate("contacts.company")}>
          <ShopOutlined style={{ color: "#aaa" }} />
        </Popover>
      )}
      {type === "person" && (
        <Popover content={translate("contacts.person")}>
          <UserOutlined style={{ color: "#aaa" }} />
        </Popover>
      )}
    </>
  );
};

export default ContactTypeIcon;