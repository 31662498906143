import { LinkOutlined } from "@ant-design/icons";
import { useGo, useTranslate } from "@refinedev/core";
import { Button, Flex, Tooltip, Typography } from "antd";
import React from "react";

type ContactDetailsProps = {
  contact?: any;
  address?: any;
  contact_person?: any;
};

const ContactDetails: React.FC<ContactDetailsProps> = ({
  contact,
  address,
  contact_person,
}) => {
  const translate = useTranslate();
  const go = useGo();
  if (!contact && !address) return null;

  return (
    <>
      <Flex vertical={true}>
        <Flex gap={12} align={"center"}>
          <Typography.Text strong>
            {contact?.type === "company" && (
              <>
                {contact?.company_name} {contact?.company_line_2}
              </>
            )}
            {contact?.type === "person" && (
              <>
                {contact?.title && <>{contact?.title}</>} {contact?.firstname}{" "}
                {contact?.lastname}
              </>
            )}
          </Typography.Text>

          <Tooltip title={translate("contacts.goto")}>
            <Button
              onClick={() => {
                go({
                  to: {
                    resource: "contacts",
                    action: "show",
                    id: contact?.id,
                  },
                });
              }}
              type={"text"}
            >
              <LinkOutlined />
            </Button>
          </Tooltip>
        </Flex>
        <Flex>
          {contact_person?.title && <>{contact_person?.title}</>}{" "}
          {contact_person?.firstname} {contact_person?.lastname}
        </Flex>
        {address && (
          <Flex vertical>
            {address?.street && <span>{address?.street}</span>}
            {(address?.zip || address?.city) && (
              <span>
                {address?.zip} {address?.city}
              </span>
            )}
            {address?.country && address?.country != "de" && (
              <span>
                {translate(`countries.${address?.country?.toUpperCase()}`)}
              </span>
            )}
          </Flex>
        )}
      </Flex>
    </>
  );
};

export default ContactDetails;
