/**
 * Safely get a nested value from an object.
 * @param obj The object to retrieve the value from.
 * @param path The path to the nested value (e.g., "metadata.module.shop.enabled").
 * @param defaultValue The default value to return if the path does not exist.
 */
export const getNestedValue = <T,>(
  obj: Record<string, any>,
  path: string,
  defaultValue: T
): T => {
  return (
    (path
      .split(".")
      .reduce(
        (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
        obj
      ) as T) ?? defaultValue
  );
};

/**
 * Safely set a nested value in an object.
 * @param obj The object to modify.
 * @param path The path to the nested value (e.g., "metadata.module.shop.enabled").
 * @param value The value to set at the specified path.
 */
export const setNestedValue = (
  obj: Record<string, any>,
  path: string,
  value: any
): void => {
  const keys = path.split(".");

  keys.reduce((acc: Record<string, any>, key: string, idx: number) => {
    if (typeof key !== "string") {
      throw new Error(`Key must be a string, but got: ${typeof key}`);
    }

    if (idx === keys.length - 1) {
      acc[key] = value; // Set the value at the final key
    } else {
      if (typeof acc[key] !== "object" || acc[key] === null) {
        acc[key] = {}; // Initialize intermediate key as an object
      }
    }
    return acc[key];
  }, obj);
};
