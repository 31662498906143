import { useSelect } from "@refinedev/antd";
import { CrudFilters, useCreate, useTranslate } from "@refinedev/core";
import { Button, Divider, Form, Select, Spin } from "antd";
import debounce from "lodash/debounce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { getContactFormatedName } from "../../utility/contactName";
import CreateContactModal from "./CreateContactModal";
import ContactTypeIcon from "./ContactTypeIcon";

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
  item?: any;
}

type Props = {
  /** The base contact ID for which the connections should be fetched */
  contactId: number;
  name?: string;
  label?: string;
  initialValue?: string | number;
  initialOption?: { value: string | number; label: React.ReactNode };
  onSelect?: (value: string | undefined | number) => void;
  getContact?: (contact: any) => void;
  filterIds?: string[];
  filter?: CrudFilters;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  disableCreate?: boolean;
  disableType?: string;
  contact_has_contact_id?: number;
  showConnected?: boolean;
};

export default function ContactAutoSelectForConnection(props: Props) {
  const {
    contactId,
    label,
    name,
    onSelect,
    getContact,
    initialValue,
    filter,
    disableCreate = false,
    required = true,
    disabled,
    placeholder,
    disableType,
    contact_has_contact_id,
    showConnected = true,
  } = props;

  const translate = useTranslate();
  const { currentTeam } = useUser();

  const [searchValue, setSearchValue] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<any>(null);

  const [relatedContactIds, setRelatedContactIds] = useState<number[]>([]);

  const { query: relationData } = useSelect({
    resource: "contact_has_contact",
    filters: [
      {
        operator: "or",
        value: [
          { field: "contact_id", operator: "eq", value: contactId },
          { field: "child", operator: "eq", value: contactId },
        ],
      },
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
    ],
    queryOptions: { enabled: !!contactId },
  });

  useEffect(() => {
    const records = relationData?.data?.data;
    if (records) {
      const ids = records.map((record: any) =>
        record.contact_id === contactId ? record.child : record.contact_id
      );
      setRelatedContactIds(ids);
    }
  }, [relationData?.data?.data, contactId]);

  const buildFilters = useCallback(
    (queryStr: string): CrudFilters => {
      const filters: CrudFilters = [];
      if (showConnected) {
        if (relatedContactIds.length > 0) {
          filters.push({
            field: "id",
            operator: "in",
            value: relatedContactIds,
          });
        } else {
          filters.push({ field: "id", operator: "eq", value: -1 });
        }
      } else {
        if (relatedContactIds.length > 0) {
          const exclusionIds = [contactId, ...relatedContactIds];
          filters.push({
            field: "id",
            operator: "nin",
            value: `(${exclusionIds.join(",")})`,
          });
        }
        filters.push({ field: "id", operator: "ne", value: contactId });
      }
      if (queryStr) {
        filters.push({
          operator: "or",
          value: [
            { field: "firstname", operator: "contains", value: queryStr },
            { field: "lastname", operator: "contains", value: queryStr },
            { field: "company_name", operator: "contains", value: queryStr },
          ],
        });
      }

      filters.push({
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      });

      return filter ? [...filters, ...filter] : filters;
    },
    [relatedContactIds, currentTeam?.account_id, filter]
  );

  const { query, selectProps } = useSelect({
    resource: "contacts",
    filters: buildFilters(searchValue),
    defaultValue: initialValue,
    optionLabel(item) {
      return (
        getContactFormatedName(
          item.company_name,
          item.firstname,
          item.lastname,
          item.email
        ) ?? ""
      );
    },
    queryOptions: {
      enabled: !!contactId && !relationData?.isLoading,
      onSuccess: () => {
        setLoading(false);
      },
    },
    sorters: [{ field: "created_at", order: "desc" }],
  });
  useEffect(() => {
    if (contactId) {
      query.refetch();
    }
  }, [searchValue, contactId]);

  const handleSearch = useCallback(
    debounce((val: string) => {
      setLoading(true);
      setSearchValue(val);
    }, 300),
    []
  );

  const handleClear = () => {
    setSearchValue("");
    onSelect?.(undefined);
    getContact?.(undefined);
  };

  const handleChange: any = (selectedValue: string, option: any) => {
    const selectedItem: any = query.data?.data.find(
      (item: any) => item.id == selectedValue
    );
    onSelect?.(selectedValue);
    getContact?.(selectedItem);
  };

  const { mutateAsync: createConnectionAsync } = useCreate({
    resource: "contact_has_contact",
  });

  const handleCreateSuccess = async (contact: any) => {
    try {
      await createConnectionAsync({
        values: {
          contact_id: contactId,
          child: contact.id,
          account: currentTeam?.account_id,
        },
      });
      relationData.refetch();
      query.refetch();
      setOpenCreateModal(false);
      onSelect?.(contact.id);
      getContact?.(contact);

      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    } catch (error) {
      console.error("Error creating contact connection:", error);
    }
  };

  return (
    <>
      {currentTeam?.account_id && (
        <Form.Item
          name={name && name}
          label={label ? label : translate("contacts.add_new_related_contact_label")}
          rules={[{ required: required }]}
        >
          <Select
            {...selectProps}
            ref={inputRef}
            showSearch
            loading={loading || relationData?.isLoading}
            placeholder={
              placeholder ?? translate("contacts.add_new_related_contact_placeholder")
            }
            allowClear
            filterOption={false}
            onClear={handleClear}
            onSearch={handleSearch}
            onChange={handleChange}
            disabled={disabled}
            options={query?.data?.data?.map((item) => ({
              label: (
                <>
                  <ContactTypeIcon type={item.type} /> {getContactFormatedName(item.company_name, item.firstname, item.lastname, item.email)}
                </>
              ),
              value: item.id,
            }))}
            dropdownRender={(menu) => (
              <>
                {loading || relationData?.isLoading ? (
                  <Spin style={{ margin: 8 }} />
                ) : (
                  menu
                )}
                <Divider style={{ margin: "4px 0" }} />
                {!disableCreate && (
                  <Button block onClick={() => setOpenCreateModal(true)}>
                    {translate("contacts.create_new_contact")}
                  </Button>
                )}
              </>
            )}
          />
        </Form.Item>
      )}
      {openCreateModal && (
        <CreateContactModal
          open={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={handleCreateSuccess}
          disableType={disableType}
          contact_has_contact_id={contact_has_contact_id}
        />
      )}
    </>
  );
}
