import {
  useNavigation,
  useShow,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import {
  Button,
  Descriptions,
  Divider,
  Drawer,
  Input,
  List,
  Modal,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import useTeam from "../../hooks/useTeam";

interface ExceptionShowDrawerProps {
  exceptionId: string;
  visible: boolean;
  onClose: () => void;
}

const ExceptionShowDrawer: React.FC<ExceptionShowDrawerProps> = ({
  exceptionId,
}) => {
  const translate = useTranslate();
  const { mutate: updateException, isLoading: isUpdating } = useUpdate();

  const { queryResult } = useShow({
    resource: "exception_case",
    id: exceptionId,
    meta: {
      select: "*",
    },
  });
  const { teamMembers } = useUser();
  const exceptionData = queryResult?.data?.data;
  const { list } = useNavigation();
  const { personalAccount } = useTeam();
  const [commentText, setCommentText] = useState("");
  const [comments, setComments] = useState(exceptionData?.comments || []);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const addComment = () => {
    if (!commentText.trim()) return;

    const newComment = {
      date: new Date().toISOString(),
      author: personalAccount?.account_id,
      text: commentText,
    };

    const updatedComments = [newComment, ...comments];
    setComments(updatedComments);
    setCommentText("");

    updateException(
      {
        resource: "exception_case",
        id: exceptionId,
        values: {
          comments: updatedComments,
        },
        meta: {
          select: "*",
        },
      },
      {
        onSuccess: () => {
          // Optionally refetch data or update UI
        },
      }
    );
  };

  const handleResolve = () => {
    setIsConfirmModalVisible(true);
  };

  const confirmResolve = () => {
    setIsConfirmModalVisible(false);
    updateException(
      {
        resource: "exception_case",
        id: exceptionId,
        values: {
          closed_by: personalAccount?.account_id,
          closed_at: new Date().toISOString(),
        },
        meta: {
          select: "*",
        },
      },
      {
        onSuccess: () => {
          list("exceptions");
        },
      }
    );
  };

  const closeModal = () => {
    if (window.location.href.includes("contact-show")) {
      list("dashboard");
    } else {
      list("exceptions");
    }
  };

  if (!exceptionData) {
    return (
      <Drawer
        open
        destroyOnClose
        onClose={() => closeModal()}
        title={translate("exceptions.exceptionDetails")}
        width={600}
      >
        <p>{translate("exception.noDataMessage", "No data available.")}</p>
      </Drawer>
    );
  }

  return (
    <Drawer
      open
      destroyOnClose
      onClose={() => closeModal()}
      width={700}
      headerStyle={{ display: "flex", alignItems: "center" }}
      title={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography.Title level={5} style={{ margin: 0 }}>
            {translate("exceptions.exceptionDetails")}
          </Typography.Title>
          <div>
            {exceptionData.tags?.map((tag: any, i: number) => (
              <Tag color={tag.color ?? "blue"} key={i}>
                {tag.name}
              </Tag>
            ))}
          </div>
        </div>
      }
    >
      <div>
        <h4>{translate("exceptions.related_resource_type")}</h4>
        <Descriptions.Item
          label={translate(
            "exception.relatedResourceType",
            "Related Resource Type"
          )}
        >
          {exceptionData.related_resource_type}
        </Descriptions.Item>
      </div>
      <Divider />
      <div>
        <h4>{translate("exceptions.exception_type")}</h4>
        <Descriptions.Item
          label={translate("exception.type", "Exception Type")}
        >
          {exceptionData.exception_type}
        </Descriptions.Item>
      </div>
      <Divider />
      <div>
        <h4>{translate("exceptions.description")}</h4>
        <Descriptions.Item
          label={translate("exception.description", "Description")}
        >
          {exceptionData.description ||
            translate("exception.noDescription", "-")}
        </Descriptions.Item>
      </div>
      <Divider />
      <div>
        <h4>{translate("exceptions.comments")}</h4>

        <Input
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          onPressEnter={addComment}
          style={{ marginBottom: "10px" }}
        />
        <Button
          type="primary"
          onClick={addComment}
          disabled={!commentText.trim()}
          style={{ marginBottom: "20px", padding: "2px" }}
        >
          {translate("exceptions.addComment", "Add Comment")}
        </Button>

        {comments.length > 0 && (
          <List
            dataSource={comments}
            renderItem={(comment: any) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <Space>
                      <span>
                        {teamMembers.find((u) => u.user_id === comment.author)
                          ?.name || "Unknown"}
                      </span>
                      <span>{new Date(comment.date).toLocaleString()}</span>
                    </Space>
                  }
                  description={comment.text}
                />
              </List.Item>
            )}
          />
        )}
      </div>

      {exceptionData.closed_at && (
        <>
          <div>
            <h4>{translate("exceptions.closed_at")}</h4>
            <Descriptions.Item
              label={translate("exception.createdAt", "Created At")}
            >
              {exceptionData.closed_at
                ? new Date(exceptionData.closed_at).toLocaleString()
                : translate("exception.noCloseDate", "Not closed yet")}
            </Descriptions.Item>
          </div>
          <div></div>
          <div>
            <h4 style={{ marginTop: "20px" }}>
              {translate("exceptions.closed_by")}
            </h4>{" "}
            <Descriptions.Item>
              {teamMembers.find((u) => u.user_id === exceptionData.closed_by)
                ?.name ?? ""}
            </Descriptions.Item>
          </div>
          <Divider />
        </>
      )}

      <div>
        {!exceptionData.closed_at && (
          <>
            <Button type="primary" onClick={handleResolve} loading={isUpdating}>
              {translate("exceptions.resolveButton", "Resolve / Lösen")}
            </Button>
            <Button style={{ marginLeft: "10px" }} onClick={() => closeModal()}>
              {translate("exceptions.close", "Close Drawer")}
            </Button>
          </>
        )}
      </div>

      <Modal
        open={isConfirmModalVisible}
        onOk={confirmResolve}
        onCancel={() => setIsConfirmModalVisible(false)}
        confirmLoading={isUpdating}
        title={translate("exceptions.confirmResolveTitle", "Confirm Resolve")}
      >
        {translate(
          "exceptions.confirmResolveMessage",
          "Are you sure you want to resolve this exception case?"
        )}
      </Modal>
    </Drawer>
  );
};

export default ExceptionShowDrawer;
