export function getContactFormatedName(
  company_name?: string | undefined,
  firstname?: string | undefined,
  lastname?: string | undefined,
  email?: string | undefined,
  name_prefix?: string | undefined
) {
  if (!firstname && !lastname && !company_name) {
    return email;
  }

  let str = "";

  if (name_prefix) {
    str += name_prefix;
  }

  if (firstname) {
    str += (str ? " " : "") + firstname;
  }

  if (lastname) {
    str += (str ? " " : "") + lastname;
  }

  if (company_name) {
    str += (str ? ", " : "") + company_name;
  }
  return str;
}
