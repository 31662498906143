import { PlusOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  Divider,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Result,
  Select,
  Space,
  Typography,
} from "antd";
import { useWatch } from "antd/es/form/Form";
import dayjs from "dayjs";
import React, { useState } from "react";
import { getContactFormatedName } from "../../../utility/contactName";
import AddressAutoselect from "../../common/AddressAutoselect";
import ContactAutoSelect from "../../contacts/ContactAutoSelect";
import ContactAutoSelectForConnection from "../../contacts/ContactAutoSelectForConnection";
import InvoiceTable from "../product-items";
import SinglePhase from "../single-phase";
import SubscriptionPhaseShowStartEnd from "./SubscriptionPhaseShowStartEnd";

interface EditOrderProps {
  order: any;
  visible: boolean;
  onClose: () => void;
}

const EditOrder: React.FC<EditOrderProps> = ({ order, visible, onClose }) => {
  const translate = useTranslate();
  const [confirmVisible, setConfirmVisible] = useState(false);

  const { formProps, form } = useForm({
    action: "edit",
    resource: "orders",
    id: order?.id,
    redirect: false,
    onMutationSuccess: async (updatedData) => {
      form.setFieldsValue(updatedData.data);
      onClose();
    },
    invalidates: ["all"],
    mutationMode: "pessimistic",
  });

  const deliveryContact = Form.useWatch("delivery_contact", form);

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      setConfirmVisible(true);
    } else {
      onClose();
    }
  };

  const handlePhaseDelete = (index: number) => {
    const updatedPhases = order?.components.filter(
      (_item, idx) => idx !== index
    );
    form.setFieldValue("components", updatedPhases);
    form.submit();
  };

  const handleChangesOnPhases = (phase: any, index: number) => {
    const updatedPhases = order?.components.map((item: any, idx: number) =>
      idx === index ? phase : item
    );
    form.setFieldValue("components", updatedPhases);
  };

  const handleAddNewPhase = () => {
    const lastPhase = order?.components[order?.components?.length - 1];
    const newPhase = lastPhase
      ? {
          ...lastPhase,
          key: lastPhase.key + 1,
          start: lastPhase.start,
          end: lastPhase.end,
          billing_interval: { ...lastPhase.billing_interval },
          products: lastPhase?.products?.map((item: any) => ({ ...item })),
        }
      : {
          key: 1,
          billing_interval: { interval: "month", interval_count: 3 },
          start: dayjs().format(translate("global.date_format")),
          end: dayjs().format(translate("global.date_format")),
          products: [],
        };

    const updatedPhases = [...order.components, newPhase];
    form.setFieldValue("components", updatedPhases);
    form.submit();
  };

  const watchedStatus = useWatch("status", form);

  const statusItems = [
    ...(order?.status === "upcoming"
      ? [
          {
            value: "upcoming",
            label: translate("subscriptions.status.upcoming"),
          },
        ]
      : []),
    {
      value: "active",
      label: translate("subscriptions.status.active"),
      disabled: ["upcoming", "terminated"].includes(order?.status),
    },
    {
      value: "canceled",
      label: translate("subscriptions.status.canceled"),
      disabled: ["upcoming", "terminated"].includes(order?.status),
    },
    {
      value: "terminated",
      label: translate("subscriptions.status.terminated"),
      disabled: ["upcoming"].includes(order?.status),
    },
  ];

  return (
    <Form
      layout="vertical"
      {...formProps}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
    >
      <Modal
        title={translate("orders.edit_single_order")}
        width={"70%"}
        style={{ minWidth: "900px", maxWidth: "1200px" }}
        open={visible}
        onCancel={handleCancel}
        onOk={() => form.submit()}
      >
        <Form.Item label={translate("settings.invoices.invoice_contact")}>
          <Space>
            <Typography.Text strong>
              {getContactFormatedName(
                order?.invoice_contact?.company_name,
                order?.invoice_contact?.firstname,
                order?.invoice_contact?.lastname,
                order?.invoice_contact?.email
              )}
            </Typography.Text>
            <Typography.Text type={"secondary"}>
              ({translate("billing_details.recipient")})
            </Typography.Text>
          </Space>
        </Form.Item>

        <Form.Item
          label={
            <>
              <Flex gap={8} align={"center"} justify={"flex-end"}>
                <span
                  style={{
                    display: "block",
                    borderRadius: 4,
                    width: 18,
                    height: 18,
                    backgroundColor:
                      watchedStatus === "active"
                        ? "green"
                        : watchedStatus === "terminated"
                        ? "red"
                        : "orange",
                  }}
                />
                {translate("billing_details.status_label")}
              </Flex>
            </>
          }
          extra={translate(`subscriptions.status.${watchedStatus}_info`)}
          name={"status"}
        >
          <Select
            options={statusItems}
            onChange={(value) => form.setFieldValue("status", value)}
          />
        </Form.Item>

        <ContactAutoSelectForConnection
          contactId={order?.invoice_contact?.id}
          showConnected={true}
          name={"contact_person_id"}
          label={translate("contacts.contact_persons")}
          onSelect={(value) =>
            form.setFieldValue("contact_person_id", value || null)
          }
          required={false}
        />
        <AddressAutoselect
          contactId={order?.invoice_contact?.id}
          name={"invoice_contact_address"}
          label={translate("subscriptions.fields.invoice_contact_address")}
          onSelect={(value) =>
            form.setFieldValue("invoice_contact_address", value || null)
          }
          required={false}
        />
        <Divider />
        <ContactAutoSelect
          //key={order?.delivery_contact}
          label={translate("subscriptions.fields.delivery_contact")}
          name={"delivery_contact"}
          required={false}
          onSelect={(value) => {
            form.setFieldValue("delivery_contact_address", null);
            form.setFieldValue("delivery_contact", value || null);
          }}
        />
        <AddressAutoselect
          contactId={deliveryContact || 0}
          //key={order?.delivery_contact_address}
          label={translate("orders.fields.delivery_contact_address")}
          name="delivery_contact_address"
          required={false}
          onSelect={(value) =>
            form.setFieldValue("delivery_contact_address", value || null)
          }
        />
        <Divider />
        {order.type == "simple" && (
          <InvoiceTable
            initialValues={order?.components?.products}
            initialShipping={order?.components?.shipping}
          />
        )}
        {order.type == "subscription" && (
          <>
            <List
              dataSource={order?.components}
              renderItem={(phase: any, index: number) => (
                <List.Item key={index}>
                  <List.Item.Meta
                    title={
                      <SubscriptionPhaseShowStartEnd
                        key={index}
                        item={phase}
                        index={index}
                      />
                    }
                    description={
                      <SinglePhase
                        handleChange={(current) =>
                          handleChangesOnPhases(current, index)
                        }
                        handlePhaseDelete={() => handlePhaseDelete(index)}
                        data={phase}
                        totalPhases={order?.components?.length}
                        index={index}
                        isSubscription
                      />
                    }
                  />
                  <Form.Item name="components">
                    <Input hidden />
                  </Form.Item>
                </List.Item>
              )}
            />

            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={handleAddNewPhase}
              style={{ fontSize: "0.80rem", fontWeight: 500, padding: 7 }}
            >
              {translate("subscription_products.list.add")}
            </Button>
          </>
        )}
      </Modal>

      <Modal
        open={confirmVisible}
        onOk={() => {
          setConfirmVisible(false);
          form.resetFields();
          onClose();
        }}
        onCancel={() => setConfirmVisible(false)}
        okText="Ja"
        cancelText="Nein"
        okButtonProps={{ danger: true }}
      >
        <Result status="warning" title={translate("warnWhenUnsavedChanges")} />
      </Modal>
    </Form>
  );
};

export default EditOrder;
