import { MoneyCollectOutlined } from "@ant-design/icons";
import { List, useTable } from "@refinedev/antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import { Button, Drawer, Table, Tag } from "antd";
import dayjs from "dayjs";
import { PropsWithChildren, useState } from "react";
import CustomBreadcrumb from "../../../components/common/CustomBreadcrumb";
import { useUser } from "../../../contexts/ContextProvider";
import i18n from "../../../i18n";

export default function PaymentsList({ children }: PropsWithChildren) {
  const { currentTeam } = useUser();
  const translate = useTranslate();
  const { tableProps } = useTable({
    resource: "payments",
    filters: {
      permanent: [
        { field: "account", operator: "eq", value: currentTeam?.account_id },
      ],
    },
    meta: { select: "*, invoice(*)" },
  });

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<any>(null);
  const { show } = useNavigation();

  const handleInvoiceClick = (invoice: any) => {
    setSelectedInvoice(invoice);
    setIsDrawerVisible(true);
  };

  // Close Drawer
  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setSelectedInvoice(null);
  };

  const breadcrumbItems = [
    {
      icon: <MoneyCollectOutlined />,
      title: translate("commerce.payments"),
    },
  ];

  const handleSendInvoice = () => {
    if (!selectedInvoice) return;

    try {
      show("invoices", selectedInvoice.id);
    } catch (error) {
      console.log("Error navigating to invoice:", error);
    }
  };

  // Fetch related payments for selected invoice
  const { tableProps: paymentsOfInvoice } = useTable({
    resource: "payments",
    filters: {
      permanent: [
        { field: "account", operator: "eq", value: currentTeam?.account_id },
        { field: "invoice", operator: "eq", value: selectedInvoice?.id },
      ],
    },
    meta: { select: "*, invoice(*)" },
  });

  return (
    <List
      title={
        <CustomBreadcrumb
          items={breadcrumbItems}
          extra={translate("commerce.payments_subtitle")}
        />
      }
    >
      <Table
        {...tableProps}
        rowKey={"id"}
        columns={[
          {
            title: translate("payments.amount"),
            dataIndex: "amount",
            key: "amount",
            render: (amount) =>
              `${Number(amount.toFixed(2)).toLocaleString(
                i18n.language ?? "de",
                {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }
              )} €`, // Format and place the currency symbol after the amount
            sorter: (a, b) => a.amount - b.amount,
          },

          {
            title: translate("payments.invoice"),
            dataIndex: "invoice",
            render: (value) =>
              value.invoice_number ? <Tag>{value.invoice_number}</Tag> : <></>,
          },
          {
            title: translate("payments.date"),
            dataIndex: "date",

            render: (value) =>
              value
                ? dayjs(value).format(translate("page.settings.date_format"))
                : null,
          },
        ]}
        onRow={(record) => ({
          onClick: () => handleInvoiceClick(record.invoice),
          style: { cursor: "pointer" }, // Change cursor to indicate clickability
        })}
      />
      {children}

      <Drawer
        title={translate("settings.invoices.invoice_details")}
        width={700}
        onClose={handleCloseDrawer}
        open={isDrawerVisible}
      >
        {selectedInvoice ? (
          <div>
            <p>
              <strong>
                {translate("settings.invoices.fields.invoice_number")}:
              </strong>{" "}
              {selectedInvoice.invoice_number}
            </p>
            <p>
              <strong>{translate("settings.contact")}:</strong>{" "}
              {selectedInvoice?.contact_name || "No contact provided"}
            </p>
            <p>
              <strong>{translate("settings.invoices.fields.amount")}:</strong>{" "}
              {selectedInvoice.amount
                ? `${Number(selectedInvoice.amount.toFixed(2)).toLocaleString(
                    i18n.language ?? "de",
                    {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }
                  )} €`
                : ""}
            </p>

            <p>
              <strong>
                {translate("settings.invoices.fields.payments_of_this_invoice")}
                :
              </strong>
            </p>
            <Table
              {...paymentsOfInvoice}
              rowKey="id"
              dataSource={paymentsOfInvoice?.dataSource || []}
              pagination={false}
              columns={[
                {
                  title: translate("payments.date"),
                  dataIndex: "date",
                  render: (value) =>
                    dayjs(value).format(translate("page.settings.date_format")),
                },
                {
                  title: translate("payments.amount"),
                  dataIndex: "amount",
                },
              ]}
            />
            <Button
              type="primary"
              onClick={handleSendInvoice}
              style={{ marginTop: 20 }}
            >
              {translate("settings.invoices.fields.go_to_invoice_button")}
            </Button>
          </div>
        ) : (
          <p>{translate("global.loading")}</p>
        )}
      </Drawer>
    </List>
  );
}
