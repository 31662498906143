import { AntDValueEditor, QueryBuilderAntD } from "@react-querybuilder/antd";
import { useTranslate } from "@refinedev/core";
import React, { useCallback, useEffect, useMemo } from "react";
import QueryBuilder, {
  CombinatorSelectorProps,
  defaultOperators,
  Field,
  getOption,
  RuleGroupType,
  RuleType,
  ValueEditorProps,
} from "react-querybuilder";
import { parseJsonLogic } from "react-querybuilder/dist/parseJsonLogic";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";

type Props = {
  handleChange: (value: any) => void;
  initialQuery?: RuleGroupType;
  showInWeek?: boolean;
};
const defaulQuery: RuleGroupType = { combinator: "and", rules: [] };

export const CustomValueEditor = (props: ValueEditorProps) => {
  return <AntDValueEditor {...props} />;
};

const CombinatorSelector = (props: CombinatorSelectorProps) => {
  // if (props.value?.toLocaleLowerCase() == "and") {
  //   props.handleOnChange("or");
  // }
  if (props.level == 0) {
    return (
      <div className={props.className} title={props.title}>
        {getOption(props.options, "and")?.label}
      </div>
    );
  }
  return (
    <div className={props.className} title={props.title}>
      {getOption(props.options, "or")?.label}
    </div>
  );
};

export default function CustomQueryBuilderIssueExport({
  handleChange,
  initialQuery,
  showInWeek = false,
}: Props) {
  const translate = useTranslate();
  const [fields, setFields] = React.useState();
  const [query, setQuery] = React.useState(() =>
    initialQuery
      ? "combinator" in initialQuery || "rules" in initialQuery
        ? initialQuery
        : parseJsonLogic(initialQuery)
      : defaulQuery
  );
  const { currentTeam } = useUser();
  const inputTypeMap = useMemo(
    () => ({
      bigint: "number",
      text: "text",
      "timestamp with time zone": "datetime-local",
      "USER-DEFINED": "text",
      uuid: "text",
      jsonb: "text",
      date: "date",
      integer: "number",
    }),
    []
  );

  const validator = (r: RuleType) => !!r.value;

  const getTableColumns = useCallback(async () => {
    const { data } = await supabaseClient.rpc("get_products_table_columns", {
      p_account: currentTeam?.account_id,
    });

    const formFields = data?.map((item) => ({
      name: item.column_name,
      label: item.label ? item.label : translate(item.column_name),
      inputType: item.options
        ? "select"
        : inputTypeMap[item.data_type] || "text",
      valueEditorType: item.options ? "select" : undefined,
      placeholder: `Enter ${item.column_name?.replace(/_/g, " ")}`,
      validator,
      ...(item.options
        ? {
            values: item.options.map((value) => ({
              name: value,
              label: value,
            })),
          }
        : {}),
    }));
    formFields.push(
      {
        name: "issues_left",
        label: "Issues Left",
        inputType: "number",
        placeholder: "Enter Issues",
        validator,
      },
      {
        name: "phases.products.quantity",
        label: "Quantity",
        inputType: "number",
        placeholder: "Enter quantity",
        validator,
      }
    );
    setFields(formFields);
  }, [currentTeam?.account_id, inputTypeMap]);

  useEffect(() => {
    if (fields === undefined) {
      getTableColumns();
    }
  }, [fields, getTableColumns]);

  const getOperators = (
    _fieldName: string,
    { fieldData }: { fieldData: Field }
  ) => {
    switch (fieldData.inputType) {
      case "text":
        return [
          { name: "=", label: "is" },
          { name: "!=", label: "is not" },
          ...defaultOperators.filter((op) =>
            [
              "contains",
              "beginsWith",
              "endsWith",
              "doesNotContain",
              "doesNotBeginWith",
              "doesNotEndWith",
              "null",
              "notNull",
              "in",
              "notIn",
            ].includes(op.name)
          ),
        ];
      case "number":
        return [
          ...defaultOperators.filter((op) => ["=", "!="].includes(op.name)),
          { name: "<", label: "less than" },
          { name: "<=", label: "less than or equal to" },
          { name: ">", label: "greater than" },
          { name: ">=", label: "greater than or equal to" },
          ...defaultOperators.filter((op) =>
            ["null", "notNull"].includes(op.name)
          ),
        ];
      case "datetime-local":
      case "date":
        return [
          { name: "=", label: "on" },
          { name: "!=", label: "not on" },
          { name: "<", label: "before" },
          { name: "<=", label: "on or before" },
          { name: ">", label: "after" },
          { name: ">=", label: "on or after" },
          ...(showInWeek
            ? [
                { name: "within", label: "within" },
                { name: "inXDays", label: "inXDays" },
              ]
            : []),
          ...defaultOperators.filter((op) =>
            ["null", "notNull"].includes(op.name)
          ),
        ];
      case "select":
        return [
          { name: "=", label: "is" },
          { name: "!=", label: "is not" },
          { name: "in", label: "in" },
          { name: "notIn", label: "not in" },
          { name: "null", label: "is null" },
          { name: "notNull", label: "is not null" },
        ];
    }
    return defaultOperators;
  };

  if (!fields) {
    return null;
  }
  return (
    <div>
      <QueryBuilderAntD>
        <QueryBuilder
          controlClassnames={{
            removeGroup: "ant-btn-sm",
            removeRule: "ant-btn-sm",
            addRule: "ant-btn-sm",
            addGroup: "ant-btn-sm",
          }}
          fields={fields}
          query={query}
          onQueryChange={(qr) => {
            setQuery(qr);
            handleChange(qr);
          }}
          enableMountQueryChange={false}
          getOperators={getOperators}
          controlElements={{
            valueEditor: CustomValueEditor,
            // addGroupAction: () => {
            //   return null;
            // },
            combinatorSelector: CombinatorSelector,
          }}
          showShiftActions
        />
      </QueryBuilderAntD>
    </div>
  );
}
