import React, { useRef, useEffect } from "react";
import { GlobalWorkerOptions, getDocument } from "pdfjs-dist";

// Verwende die vorhandene Worker-Datei aus dem Build-Ordner
GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

export const PdfViewer = ({ fileURL }: { fileURL: string }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const renderTaskRef = useRef<any>(null); // Track the current render task
  const containerRef = useRef<HTMLDivElement | null>(null); // Track the container div

  useEffect(() => {
    const renderPdf = async () => {
      const container = containerRef.current; // Capture the container reference
      if (!fileURL || !container) return;

      // Clear the container to ensure a fresh render environment
      container.innerHTML = "";

      const canvas = document.createElement("canvas");
      canvas.style.width = "100%"; // Ensure the canvas adjusts to the parent's width
      canvas.style.height = "auto"; // Maintain aspect ratio
      container.appendChild(canvas);

      const context = canvas.getContext("2d");

      if (!context) {
        console.error("Failed to get 2D context for canvas.");
        return;
      }

      // Cancel the previous render task if it's still running
      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
        renderTaskRef.current = null;
      }

      try {
        const pdf = await getDocument(fileURL).promise;
        const page = await pdf.getPage(1);

        const viewport = page.getViewport({ scale: 1.5 });
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        } as any; // Type assertion to avoid null issue

        // Store and execute the render task
        renderTaskRef.current = page.render(renderContext);
        await renderTaskRef.current.promise;
        renderTaskRef.current = null; // Clear the render task
      } catch (error) {
        if (
          error instanceof Error &&
          error.name === "RenderingCancelledException"
        ) {
          console.warn("Rendering was cancelled.");
        } else {
          console.error("Error rendering PDF:", error);
        }
      }
    };

    renderPdf();

    // Cleanup on unmount or when fileURL changes
    return () => {
      const cleanupContainer = containerRef.current; // Capture a stable reference for cleanup

      if (renderTaskRef.current) {
        renderTaskRef.current.cancel();
        renderTaskRef.current = null;
      }

      if (cleanupContainer) {
        cleanupContainer.innerHTML = "";
      }
    };
  }, [fileURL, containerRef]); // Added containerRef to dependency array

  return (
    <div
      ref={containerRef}
      style={{
        border: "1px solid #d9d9d9",
        borderRadius: "4px",
        padding: "16px",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        overflow: "hidden",
        width: "100%",
      }}
    ></div>
  );
};

export default PdfViewer;
