import { MailOutlined } from "@ant-design/icons";
import { useOne, useTranslate } from "@refinedev/core";
import {
  Button,
  Form,
  Input,
  Modal,
  Radio,
  Space,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { supabaseClient } from "../../utility";

interface ResendInvoiceModalProps {
  visible: boolean;
  onClose: () => void;
  invoice: any;
}

const ResendInvoiceModal: React.FC<ResendInvoiceModalProps> = ({
  visible,
  onClose,
  invoice,
}) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const [form] = Form.useForm();
  const [method, setMethod] = useState<"letter" | "email">("letter");
  const [isLoading, setIsLoading] = useState(false);
  const { data: sender } = useOne({
    resource: "email_sender_addresses",
    id: currentTeam?.metadata?.module?.shop?.sender_email,
  });

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      const filePath = `teams/${currentTeam?.account_id}/invoices/${invoice.invoice_number}.pdf`;
      let payload = {};

      if (method == "email") {
        // Keeping email body and subject in German
        payload = {
          from: sender?.data.email,
          to: values.email,
          attachments: [
            {
              path: filePath,
              name: `${invoice.invoice_number}.pdf`,
            },
          ],
          body: values.body,
          subject: values.subject,
          account: currentTeam?.account_id,
          mail_outbox_type: "invoice",
          mail_outbox_id: invoice.id,
          from_name: sender?.data?.name,
          signature: sender?.data?.signature,
        };
      } else {
        if (!invoice.contact.invoice_postal_mail) {
          message.error(
            translate("settings.invoices.invoice_postal_mail_missing")
          );
          onClose();
          return;
        }
        payload = {
          type: "letter",
          attachments: [
            { path: filePath, name: `${invoice.invoice_number}.pdf` },
          ],
          account: currentTeam?.account_id,
          mail_outbox_type: "invoice",
          mail_outbox_id: invoice.id,
        };
      }

      if (payload) {
        const { data, error: resendError } =
          await supabaseClient.functions.invoke("invoices/resend-invoice", {
            body: payload,
            method: "POST",
          });
        if (resendError) {
          throw resendError;
        }
        if (data) {
          message.success(translate("settings.invoices.invoice_resent"));
          onClose();
        }
        // await sendLetter(
        //   {
        //     resource: "mail_outbox",
        //     values: payload,
        //   },
        //   {
        //     onError(error) {
        //       throw error;
        //     },
        //     onSuccess() {
        //       message.success(translate("settings.invoices.invoice_resent"));
        //       onClose();
        //     },
        //   }
        // );
      }
      setIsLoading(false);
    } catch (error) {
      message.error(translate("settings.invoices.invoice_resend_failed"));
    }
  };
  const dateObject = new Date(invoice.date ?? "");
  const formattedDate = dateObject.toLocaleDateString("de-DE");

  return (
    <Modal
      title={translate("settings.invoices.resend_invoice_title")}
      open={visible}
      onCancel={onClose}
      onOk={handleSend}
      okText={translate("settings.invoices.send_now")}
      confirmLoading={isLoading}
      centered
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          email: invoice.contact.email_invoices || invoice.contact_email,
          subject: `Rechnung ${invoice.invoice_number}`,
          body: `Sehr geehrte Damen und Herren,

im Anhang finden Sie Ihre Rechnung ${invoice.invoice_number} vom ${formattedDate}.

Bei Fragen stehen wir Ihnen gerne zur Verfügung.

Mit freundlichen Grüßen,`,
        }}
      >
        <Form.Item>
          <Radio.Group
            onChange={(e) => setMethod(e.target.value)}
            value={method}
          >
            <Radio value="letter">
              {translate("settings.invoices.letter")}
            </Radio>
            <Radio value="email">{translate("settings.invoices.email")}</Radio>
          </Radio.Group>
        </Form.Item>

        {method === "email" && (
          <>
            <Form.Item
              name="email"
              label={translate("settings.invoices.recipient_email")}
              rules={[
                {
                  required: true,
                  message: translate("settings.invoices.email_required"),
                },
                {
                  type: "email",
                  message: translate("settings.invoices.invalid_email"),
                },
              ]}
            >
              <Input placeholder={translate("settings.invoices.enter_email")} />
            </Form.Item>
            <Form.Item
              name="subject"
              label={translate("settings.invoices.email_subject")}
            >
              <Input
                placeholder={translate("settings.invoices.enter_subject")}
              />
            </Form.Item>
            <Form.Item
              name="body"
              label={translate("settings.invoices.recipient_email")}
              rules={[
                {
                  required: true,
                  message: translate("settings.invoices.body_required"),
                },
              ]}
            >
              <Input.TextArea
                rows={8}
                placeholder={translate("settings.invoices.enter_body")}
              />
            </Form.Item>
            <Form.Item label={translate("settings.invoices.signature")}>
              <Typography.Paragraph
                style={{ whiteSpace: "pre-wrap", color: "gray" }}
              >
                {sender?.data?.signature}
              </Typography.Paragraph>
            </Form.Item>
          </>
        )}

        {method === "letter" && (
          <p>{translate("settings.invoices.invoice_sent_as_letter")}</p>
        )}
      </Form>
    </Modal>
  );
};

const InvoiceResendButton: React.FC<{
  invoice: any;
}> = ({ invoice }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const translate = useTranslate();

  return (
    <>
      <Button type="default" onClick={() => setModalVisible(true)}>
        <Space>
          <MailOutlined />
          <Typography.Text>
            {translate("settings.invoices.resend_invoice")}
          </Typography.Text>
        </Space>
      </Button>
      <ResendInvoiceModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        invoice={invoice}
      />
    </>
  );
};

export default InvoiceResendButton;
