import React, {useState, useEffect} from 'react';
import {
    InstantSearch,
    Configure,
    useSearchBox,
    useHits,
    useInstantSearch,
} from 'react-instantsearch';
import {instantMeiliSearch} from '@meilisearch/instant-meilisearch';
import {Select, Typography} from 'antd';
import {useNavigate} from 'react-router-dom';
import useTeam from "../../hooks/useTeam";
import {useTranslate} from "@refinedev/core";
import {useSearchContext} from "../../contexts/SearchProvider";

const {Text} = Typography;


export default function GlobalSearch() {

    const {
        searchKey,
        //searchKeyValidUntil
    } = useSearchContext();

    const {searchClient, setMeiliSearchParams} = instantMeiliSearch(
      'https://search.tellie.io',
      searchKey, // Here we need the current team's search key
      {
          finitePagination: true,
      }
    );
    setMeiliSearchParams({
        showRankingScore: true,
    });

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <InstantSearch indexName="contacts" searchClient={searchClient}>
            <Configure
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                hitsPerPage={12}
                attributesToSnippet={['firstname:3']}
                snippetEllipsisText="..."
            />
            <div style={{padding: '1em'}}>
                <GlobalSearchSelect
                    placeholder="Suchbegriff eingeben..."
                    style={{width: '100%'}}
                />
            </div>
        </InstantSearch>
    );
}

function GlobalSearchSelect(props) {
    const {query, refine} = useSearchBox();
    const {status} = useInstantSearch();
    const {hits} = useHits();
    const isSearchStalled = status === 'stalled';
    const [inputValue, setInputValue] = useState(query);
    const navigate = useNavigate();
    const {currentTeam} = useTeam();

    useEffect(() => {
        setInputValue(query);
    }, [query]);

    const handleSearch = (value) => {
        setInputValue(value);
        refine(value);
    };

    const handleChange = (value) => {
        setInputValue(value);
    };

    const handleSelect = (value) => {
        const id = String(value).includes('_')
            ? String(value).split('_')[1]
            : String(value);
        navigate(`/${currentTeam?.slug}/contacts/show/${id}`);
    };

    function findDifferences(data) {
        const result = [];

        for (const key in data) {
            if (data[key].length > 1) {
                const ids = data[key].map(item => item.id);
                const differingFields = new Set();

                const firstItem = data[key][0];

                data[key].forEach(item => {
                    compareObjects(firstItem, item, '', differingFields);
                });

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                result.push({
                    ids: [...new Set(ids)],
                    fields: [...differingFields]
                });
            }
        }

        return result;
    }

    function compareObjects(base, compare, prefix, differingFields) {
        for (const key in base) {
            if (key.startsWith("_")) continue;
            const baseValue = base[key];
            const compareValue = compare[key];
            const fieldPath = prefix ? `${prefix}.${key}` : key;

            if (typeof baseValue === 'object' && baseValue !== null && !Array.isArray(baseValue)) {
                if (typeof compareValue === 'object' && compareValue !== null) {
                    compareObjects(baseValue, compareValue, fieldPath, differingFields);
                } else {
                    differingFields.add(fieldPath);
                }
            } else if (Array.isArray(baseValue)) {
                if (Array.isArray(compareValue)) {
                    baseValue.forEach((_, index) => {
                        if (JSON.stringify(baseValue[index]) !== JSON.stringify(compareValue[index])) {
                            differingFields.add(`${fieldPath}.${index}`);
                        }
                    });
                } else {
                    differingFields.add(fieldPath);
                }
            } else if (baseValue !== compareValue) {
                differingFields.add(fieldPath);
            }
        }
    }

    const filterHighlightedResults = (hits) => {
        const groupedContacts = {};

        // 1️⃣ Zunächst: Alle Kontakte filtern, die gleiche Werte in den Vergleichsfeldern haben
        hits.forEach(hit => {
            const keyIdentifier = [
                hit._highlightResult.firstname?.value || "",
                hit._highlightResult.lastname?.value || "",
                hit._highlightResult.name_prefix?.value || "",
                hit._highlightResult.company_name?.value || ""
            ].join("|");

            if (!groupedContacts[keyIdentifier]) {
                groupedContacts[keyIdentifier] = [];
            }
            groupedContacts[keyIdentifier].push(hit);
        });

        // 2️⃣ Ermitteln, welche Felder sich innerhalb einer Gruppe unterscheiden
        const groupDifferences = findDifferences(groupedContacts);


        // 3️⃣ Erzeugung der neuen gefilterten Datenstruktur
        return hits.map(hit => {
            const filteredData = {};
            const hitId = hit.id;

            // Finde die groupDifference für die aktuelle ID
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const groupDifference = groupDifferences.find(group => group.ids.includes(hitId));
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const differingFields = groupDifference ? new Set(groupDifference.fields) : new Set();

            Object.entries(hit._highlightResult).forEach(([key, obj]) => {

                const specialKeys = ['id', 'type', 'source_id'];

                // Vergleichs-Keys werden immer übernommen, wenn sie einen Wert haben
                if (["firstname", "lastname", "name_prefix", "company_name", "customer_number"].includes(key)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    if (obj.value && obj.value !== "null") {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        filteredData[key] = obj.value;
                    }
                }
                if (key === "id") {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    filteredData['_id'] = obj.value;
                } else if (key === "type") {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    filteredData['_type'] = obj.value;
                }
                    // Alle anderen Keys nur übernehmen, wenn sie <mark> enthalten ODER wenn sie sich von anderen Kontakten unterscheiden
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                else if (obj.value?.includes("<mark>") || differingFields.has(key) && !specialKeys.includes(key)) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    filteredData[key] = obj.value;
                }
                // Wenn es sich um ein Array von Objekten handelt (z.B. addresses)
                else if (Array.isArray(obj)) {
                    const markedItems = obj.filter((item, index) =>
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        Object.values(item).some(subObj => subObj.value?.includes("<mark>")) || differingFields.has(`${key}.${index}`) && !specialKeys.includes(key)
                    );

                    if (markedItems.length > 0) {
                        filteredData[key] = markedItems.map(item =>
                            Object.fromEntries(
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                Object.entries(item).map(([subKey, subObj]) => [subKey, subObj.value])
                            )
                        );
                    }
                }
            });

            return filteredData;
        });

    };

    const translate = useTranslate();

    return (
        <Select
            showSearch
            value={inputValue}
            placeholder={props.placeholder}
            style={props.style}
            defaultActiveFirstOption={false}
            suffixIcon={null}
            filterOption={false}
            onSearch={handleSearch}
            onChange={handleChange}
            onSelect={handleSelect}
            notFoundContent={isSearchStalled ? 'Lädt …' : null}
            dropdownRender={(menu) => (
                <div>
                    {menu}
                    {/*<div style={{padding: '8px', textAlign: 'center', borderTop: '1px solid #e8e8e8'}}>*/}
                    {/*    Custom Footer*/}
                    {/*</div>*/}
                </div>
            )}
        >
            {/*<pre>{JSON.stringify(filterHighlightedResults(hits), null, 2)}</pre>*/}
            {filterHighlightedResults(hits).map((hit) => (
                <Select.Option key={hit._id} value={hit._id}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        {hit._type === 'person' ? (
                            <Text strong>
                            <span dangerouslySetInnerHTML={{
                                __html: [hit.name_prefix, hit.firstname, hit.lastname]
                                    .filter(value => value !== null && value !== undefined && value !== '' && value !== 'null') // Entfernt null, undefined und leere Strings
                                    .join(' ')
                            }}/></Text>) : (
                            <div>
                                <Text strong>
                                    <span dangerouslySetInnerHTML={{__html: hit.company_name}}/>
                                </Text>
                            </div>
                        )}

                        <div>
                            {Object.entries(hit)
                                .filter(([key]) => !key.startsWith('_'))
                                .filter(([key, obj]) => !['name_prefix', 'firstname', 'lastname', 'company_name'].includes(key))
                                .map(([key, obj]) => (
                                    <div key={key} style={{fontSize: '0.9em', color: '#555'}}>
                                        <strong>{translate("search.keys." + key, key)}: </strong>
                                        {Array.isArray(obj) ? (
                                            key === 'addresses' ? (
                                                obj.map((item, index) => (
                                                    <div key={index} style={{marginLeft: '10px'}}>
                                                         <span dangerouslySetInnerHTML={{
                                                             __html: `${item.street}, ${item.zip} ${item.city}, ${item.country?.toUpperCase()}`
                                                         }}/>
                                                    </div>
                                                ))
                                            ) : (
                                                obj.map((item, index) => (
                                                    <div key={index} style={{marginLeft: '10px'}}>
                                                        {Object.entries(item).map(([subKey, subObj]) => (
                                                            <div key={subKey}>
                                                                <strong>{translate("search.keys." + subKey, subKey)}:</strong>{" "}
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: subObj as string}}/>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))
                                            )
                                        ) : (
                                            <span dangerouslySetInnerHTML={{__html: obj as string}}/>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>
                </Select.Option>
            ))}
        </Select>
    );
}