import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Divider, Form, Select } from "antd";
import debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import AddressModal from "../contacts/modals/AddressModal";

interface IOptionGroup {
  value: string;
  label: string | React.ReactNode;
  item?: any;
}

type Props = {
  contactId: string;
  name?: string;
  label?: string;
  initialValue?: string | number;
  required?: boolean;
  defaultActiveFirstOption?: boolean;
  onSelect?: (value: string | number) => void;
  getAddress?: (address: any) => void;
};

export default function AddressAutoselect({
  contactId,
  name,
  label,
  required = true,
  defaultActiveFirstOption = false,
  onSelect,
  getAddress,
  initialValue,
}: Props) {
  const { currentTeam } = useUser();
  const translate = useTranslate();

  const [searchValue, setSearchValue] = useState("");
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const inputRef = useRef<any>(null);

  const formatAddressLabel = (address: any) => {
    let label = "";

    if (address.type === "PO_Box_Address") {
      label += `${address.street ?? ""}\n`;
    } else if (address.type === "Packing_Station") {
      if (address.address_supplement_1) {
        label += `${translate("contacts.post_number")}: ${
          address.address_supplement_1
        }\n`;
      }
      label += `${translate("contacts.packstation")}: ${
        address.street ?? ""
      }\n`;
    } else {
      if (address.address_supplement_1) {
        label += `${address.address_supplement_1}\n`;
      }
      label += `${address.street ?? ""}\n`;
    }

    label += `${address.zip ?? ""} ${address.city ?? ""}\n`;
    if (address?.country?.toUpperCase() !== "DE" && address?.country) {
      label += `${
        translate(`countries.${address?.country?.toUpperCase()}`) ?? ""
      }\n`;
    }

    if (address.label) {
      label += `[${address.label}]\n`;
    }

    return label.trim();
  };

  const renderItem = (address: any) => {
    return {
      value: String(address.id),
      label: formatAddressLabel(address),
      item: address,
    };
  };

  const { selectProps, query } = useSelect<any>({
    resource: "addresses",
    filters: [
      {
        field: "account",
        operator: "eq",
        value: currentTeam?.account_id,
      },
      {
        field: "contacts.id",
        operator: "eq",
        value: contactId,
      },
      {
        operator: "or",
        value: [
          {
            field: "street",
            operator: "contains",
            value: `%${searchValue}%`,
          },
          {
            field: "city",
            operator: "contains",
            value: `%${searchValue}%`,
          },
          {
            field: "zip",
            operator: "contains",
            value: `%${searchValue}%`,
          },
        ],
      },
    ],
    meta: { select: "*, contacts!inner(*)" },
    optionLabel(item) {
      return formatAddressLabel(item) ?? "";
    },
  });

  useEffect(() => {
    if (currentTeam?.account_id) {
      query.refetch();
    }
  }, [searchValue, contactId, currentTeam?.account_id, query.refetch]);

  const handleSearch = debounce((val: string) => {
    setSearchValue(val);
  }, 300);

  const handleChange: any = (selectedValue: string, option: any) => {
    onSelect?.(selectedValue);
    getAddress?.(option.item);
  };

  const form = Form.useFormInstance();

  useEffect(() => {
    const fieldName = name ?? "address";
    if (
      defaultActiveFirstOption &&
      !initialValue &&
      !isCleared &&
      selectProps.options &&
      selectProps.options.length > 0
    ) {
      const currentValue = form.getFieldValue(fieldName);
      if (!currentValue) {
        const firstOption = selectProps.options[0];
        form.setFieldsValue({ [fieldName]: firstOption.value });
        firstOption.value && onSelect?.(firstOption.value);
        getAddress?.(firstOption.item);
      }
    }
  }, [
    selectProps.options,
    defaultActiveFirstOption,
    initialValue,
    isCleared,
    form,
    name,
    onSelect,
    getAddress,
  ]);

  return (
    <>
      <Form.Item
        name={name ?? "address"}
        label={label ?? translate("contacts.address")}
        rules={[{ required }]}
        initialValue={initialValue}
      >
        <Select
          {...selectProps}
          showSearch
          allowClear
          onSearch={handleSearch}
          onClear={() => {
            setSearchValue("");
            setIsCleared(true);
            onSelect?.("");
            getAddress?.(null);
          }}
          onChange={handleChange}
          filterOption={false}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: "4px 0" }} />
              <Button
                block
                size="small"
                type="default"
                ref={inputRef}
                onClick={() => setOpenCreateModal(true)}
              >
                {translate("contacts.add_new_address")}
              </Button>
            </div>
          )}
        />
      </Form.Item>

      {openCreateModal && (
        <AddressModal
          visible={openCreateModal}
          onClose={() => setOpenCreateModal(false)}
          onSuccess={(newAddress) => {
            query.refetch();
            const newOption = {
              value: String(newAddress.id),
              label: formatAddressLabel(newAddress),
              item: newAddress,
            };

            onSelect?.(newAddress.id);
            getAddress?.(newOption.item);

            setOpenCreateModal(false);
            setTimeout(() => inputRef.current?.focus(), 500);
          }}
          contactId={contactId}
        />
      )}
    </>
  );
}
