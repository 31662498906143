import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useNotification, useTranslate } from "@refinedev/core";
import { Button, Checkbox, Form, Input, Modal } from "antd";
import { useUser } from "../../../../contexts/ContextProvider";
import { supabaseClient } from "../../../../utility";

const AddSmtpConnectionModal = ({
  isAddConnectionModalVisible,
  setAddConnectionModalVisible,
  modalLoading,
  setModalLoading,
  form,
  emailValidationStatus,
  setEmailValidationStatus,
  refetch,
}) => {
  const { currentTeam } = useUser();
  const { open } = useNotification();
  const translate = useTranslate();

  return (
    <Modal
      title="Add SMTP Connection"
      open={isAddConnectionModalVisible}
      onCancel={() => setAddConnectionModalVisible(false)}
      confirmLoading={modalLoading}
      onOk={async () => {
        setModalLoading(true);
        try {
          const values: any = await form.validateFields();
          const {
            smtp_host,
            smtp_port,
            smtp_user,
            smtp_pass,
            smtp_secure,
            emails,
          } = values;

          const smtpDetails = {
            account: currentTeam?.account_id,
            account_type: "smtp",
            smtp_host,
            smtp_port,
            smtp_user,
            smtp_pass,
            smtp_secure,
            emails,
          };
          const { data, error } = await supabaseClient.functions.invoke(
            "integration-accounts/setup",
            {
              body: smtpDetails,
            }
          );
          if (error) {
            open?.({
              description: translate("alerts.smtp.title"),
              message: translate("alerts.smtp.description"),
              type: "error",
            });
          } else {
            if (data.success) {
              // Show success feedback
              await form.validateFields();
              open?.({
                description: translate("notifications.success"),
                message: translate("notifications.editSuccess"),
                type: "success",
              });
              refetch();
              setAddConnectionModalVisible(false);
            } else {
              if (data.emailValidation) {
                const emailFeedback = data.emailValidation;
                const updatedFields = emails.map((email, index) => {
                  return {
                    name: ["emails", index],
                    validateStatus: emailFeedback[email] ? "error" : "success",
                  };
                });
                setEmailValidationStatus(updatedFields);
                open?.({
                  description: translate("alerts.smtp_email.title"),
                  message: translate("alerts.smtp_email.description"),
                  type: "error",
                });
              }
            }
          }
        } catch (err) {
          setModalLoading(false);
          console.error("Validation Failed:", err);
          open?.({
            description: translate("alerts.submitError.title"),
            message: translate("alerts.submitError.defaultMessage"),
            type: "error",
          });
        } finally {
          setModalLoading(false);
        }
      }}
      centered
      destroyOnClose
    >
      {/* SMTP Settings Form */}
      <Form form={form} layout="vertical" initialValues={{ emails: [""] }}>
        <Form.Item
          label="SMTP Host"
          name="smtp_host"
          rules={[{ required: true, message: "Please enter SMTP Host" }]}
        >
          <Input placeholder="Enter SMTP Host" />
        </Form.Item>
        <Form.Item
          label="SMTP Port"
          name="smtp_port"
          rules={[{ required: true, message: "Please enter SMTP Port" }]}
        >
          <Input placeholder="Enter SMTP Port" />
        </Form.Item>
        <Form.Item
          label="SMTP User"
          name="smtp_user"
          rules={[{ required: true, message: "Please enter SMTP User" }]}
        >
          <Input placeholder="Enter SMTP User" />
        </Form.Item>
        <Form.Item
          label="SMTP Password"
          name="smtp_pass"
          rules={[{ required: true, message: "Please enter SMTP Password" }]}
        >
          <Input.Password
            placeholder="Enter SMTP Password"
            autoComplete="new-password"
          />
        </Form.Item>
        <Form.Item
          label="Secure Connection"
          name="smtp_secure"
          valuePropName="checked"
        >
          <Checkbox>Use SSL</Checkbox>
        </Form.Item>
        <Form.List
          name="emails"
          initialValue={[""]}
          rules={[
            {
              validator: async (_, emails) => {
                if (!emails || emails.length < 1) {
                  return Promise.reject(
                    new Error("At least one email is required.")
                  );
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  label={index === 0 ? "Emails" : ""}
                  required={index === 0}
                  key={field.key}
                  hasFeedback
                  validateStatus={
                    modalLoading
                      ? "validating"
                      : emailValidationStatus[index]?.validateStatus ||
                        undefined
                  }
                  help={form.getFieldError(field.name)}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-around",
                    }}
                  >
                    <div
                      style={{
                        flexBasis: "95%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div style={{ display: "flex", gap: 10 }}>
                        <Form.Item
                          {...field}
                          name={[field.name, "email"]}
                          validateTrigger={["onChange", "onBlur"]}
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid email address.",
                            },
                            {
                              required: true,
                              message:
                                "Please enter an email address or delete this field.",
                            },
                          ]}
                          // noStyle
                          style={{ flex: 1 }}
                        >
                          <Input placeholder="email@example.com" />
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, "name"]}
                          validateTrigger={["onChange", "onBlur"]}
                          style={{ flex: 1 }}
                        >
                          <Input placeholder="Name (optional)" />
                        </Form.Item>
                      </div>
                      <Form.Item
                        {...field}
                        name={[field.name, "return_path"]}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address.",
                          },
                        ]}
                        label={"Return Path"}
                        // noStyle
                        style={{ flex: 1 }}
                      >
                        <Input placeholder="email@example.com" />
                      </Form.Item>
                      <Form.Item
                        label="Signature"
                        name={[field.name, "signature"]}
                        style={{ flex: 0.8 }}
                      >
                        <Input.TextArea
                          placeholder="Enter Email signature"
                          rows={4}
                        />
                      </Form.Item>
                    </div>

                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => {
                          remove(field.name);
                          setEmailValidationStatus({});
                        }}
                        style={{ marginLeft: 10, marginTop: 10 }}
                      />
                    ) : null}
                  </div>
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "100%", display: "none" }}
                  icon={<PlusOutlined />}
                >
                  Add Email
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddSmtpConnectionModal;
