import { useSelect } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Select } from "antd";
import { formatQuery, RuleGroupType } from "react-querybuilder";
import { useUser } from "../../../contexts/ContextProvider";

const defaultQuery: RuleGroupType = { combinator: "and", rules: [] };

type Props = {
  value?: { groupIds?: any[]; segmentId?: any };
  onChange?: (value: { groupIds?: any[]; segmentId?: any }) => void;
  onClear?: (byClearButton?: boolean) => Promise<void>;
};

export default function FilterAutoComplete({
  value = { groupIds: [], segmentId: null },
  onChange,
  onClear,
}: Props) {
  const translate = useTranslate();
  const { currentTeam } = useUser();

  const { query: groupResults } = useSelect({
    resource: "tags",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const { query: segmentResults } = useSelect({
    resource: "segments",
    optionLabel: "name",
    optionValue: "id",
    filters: [
      { field: "account", operator: "eq", value: currentTeam?.account_id },
    ],
  });

  const groupOptions = groupResults.data?.data.map((item) => ({
    value: `group-${item.id}`,
    label: item.name,
    group: "Groups",
  }));

  const segmentOptions = [
    {
      value: "segment--1",
      label: translate("contacts.filter_custom"),
      group: "Segments",
    },
    ...(segmentResults?.data?.data?.map((item) => ({
      value: `segment-${item.id}`,
      label: item.name,
      group: "Segments",
    })) ?? []),
  ];

  const combinedOptions = [
    { label: translate("contacts.filter_groups"), options: groupOptions },
    { label: translate("contacts.filter_segments"), options: segmentOptions },
  ];

  const handleClear = async () => {
    await onClear?.(true);
    onChange?.({ groupIds: [], segmentId: null });
  };

  const handleChange = async (selectedValues: any[]) => {
    await onClear?.();

    const groupIds = selectedValues
      .filter((val) => val.startsWith("group-"))
      .map((val) => val.replace("group-", ""));

    const segmentId = selectedValues
      .filter((val) => val.startsWith("segment-"))
      .map((val) => val.replace("segment-", ""))
      .pop();

    if (segmentId === "-1") {
      onChange?.({
        groupIds,
        segmentId: {
          id: -1,
          name: "Custom Field",
          query: formatQuery(defaultQuery, "jsonlogic"),
        },
      });
    } else if (segmentId) {
      const segmentData = segmentResults?.data?.data.find(
        (item) => item.id === segmentId
      );
      onChange?.({ groupIds, segmentId: segmentData });
    } else {
      onChange?.({ groupIds, segmentId: null });
    }
  };

  const selectedValues = [
    ...(value.groupIds?.map((id: any) => `group-${id}`) ?? []),
    value.segmentId?.id ? `segment-${value.segmentId.id}` : null,
  ].filter(Boolean);

  return (
    <Select
      mode="multiple"
      suffixIcon={null}
      variant={"borderless"}
      allowClear
      placeholder={translate("contacts.filter_placeholder")}
      style={{ width: "100%", minWidth: "200px" }}
      options={combinedOptions}
      value={selectedValues}
      onClear={handleClear}
      onChange={handleChange}
      filterOption={(input, option) => {
        return (
          option?.label?.toLowerCase().includes(input.toLowerCase()) ?? false
        );
      }}
    />
  );
}
