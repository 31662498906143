import { useNotification, useTranslate } from "@refinedev/core";
import { Checkbox, Form, Input, Modal } from "antd";
import { useUser } from "../../../../contexts/ContextProvider";
import { supabaseClient } from "../../../../utility";

const AddLetterXpressConnectionModal = ({
  isAddConnectionModalVisible,
  setAddConnectionModalVisible,
  modalLoading,
  setModalLoading,
  form,
  refetch,
}) => {
  const { currentTeam } = useUser();
  const { open } = useNotification();
  const translate = useTranslate();

  const handleOk = async () => {
    setModalLoading(true);
    try {
      const values: any = await form.validateFields();
      const { username, api_key, test_mode } = values;

      const details = {
        account: currentTeam?.account_id,
        account_type: "letterxpress",
        username,
        api_key,
        test_mode,
      };
      const { data, error } = await supabaseClient.functions.invoke(
        "integration-accounts/setup",
        {
          body: details,
        }
      );

      if (error) {
        open?.({
          description: translate("alerts.smtp.title"),
          message: translate("alerts.smtp.description"),
          type: "error",
        });
      } else if (data.success) {
        await form.validateFields();
        open?.({
          description: translate("notifications.success"),
          message: translate("notifications.editSuccess"),
          type: "success",
        });
        refetch();
        setModalLoading((prev) => !prev);
        setAddConnectionModalVisible(false);
      }
    } catch (err) {
      console.error("Validation Failed:", err);
      open?.({
        description: translate("alerts.submitError.title"),
        message: translate("alerts.submitError.defaultMessage"),
        type: "error",
      });
    } finally {
      console.log("confirm finally");
      setModalLoading((prev) => !prev);
    }
  };

  return (
    <Modal
      title="Add LetterXpress Connection"
      open={isAddConnectionModalVisible}
      onCancel={() => setAddConnectionModalVisible(false)}
      confirmLoading={modalLoading}
      onOk={handleOk}
      centered
    >
      <Form form={form} layout="vertical" initialValues={{}}>
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please enter Username" }]}
        >
          <Input placeholder="Enter your Username" />
        </Form.Item>
        <Form.Item
          label="Api Key"
          name="api_key"
          rules={[{ required: true, message: "Please enter Api Key" }]}
        >
          <Input placeholder="Enter your Api Key" />
        </Form.Item>
        <Form.Item
          label={translate("integrations.letterXpress.test_mode_label")}
          name="test_mode"
          valuePropName="checked"
        >
          <Checkbox>
            {translate("integrations.letterXpress.test_mode")}
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddLetterXpressConnectionModal;
