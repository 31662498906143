import React from "react";
import { Divider, Typography } from "antd";

interface DividerTitleProps {
  title: string | undefined;
}

const DividerTitle: React.FC<DividerTitleProps> = ({ title }) => {
  return (
    <Divider
      plain
      orientationMargin={0}
      orientation={"left"}
      style={{ borderColor: "#7cb305" }}
    >
      <Typography.Text strong>{title}</Typography.Text>
    </Divider>
  );
};

export default DividerTitle;
